//----------------------brand font-------------------------------------
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

//----------------------cutom values-------------------------------------
$axl-brand-background:       #1E1D24;
$axl-brand-primary:          #FFFFFF;
$axl-brand-secondary:        #F75506;
$axl-brand-tertiary:         #C0C0C0;
$axl-brand-font:             'Work Sans';

$axl-button-radius:         0.5rem;   
$axl-brand-secondary-shade:      #F97738;
$axl-brand-secondary-shade1: rgba($axl-brand-secondary-shade, .90);
$axl-brand-secondary-shade2:     #fb651a;
$axl-brand-secondary-shade3:      #fb6418;
$axl-additional-colour1:     #4B4A50;
$axl-additional-colour2:     #373737;
$axl-additional-colour3:     #808080;
$axl-additional-colour4:      #b0b0b0;
$axl-additional-colour5:      #6F6E73;
$axl-additional-colour6:      #bebebe;
$axl-additional-colour7:      #EFEFEF;
$axl-additional-colour8:  #3D3C43;


//--------------------- variable overrides--------------------------------

// Color system
//
$primary:       $axl-brand-primary;
$secondary:     $axl-brand-secondary;

// Body
//
$body-bg: $axl-brand-background;
$body-color: $axl-brand-primary;
$body-emphasis-color: $axl-brand-secondary;

$body-color:                $axl-brand-primary;
$body-bg:                   $axl-brand-background;

$body-secondary-color:      rgba($body-color, .75);
$body-secondary-bg:         $axl-brand-background;

$body-tertiary-color:       rgba($body-color, .5);
$body-tertiary-bg:          $axl-brand-background;

$body-emphasis-color:       $axl-brand-secondary;

// Typography
//
$font-family-base:            $axl-brand-font;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root:              null !default;
$font-size-base:              0.9rem !default; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875 !default;
$font-size-lg:                $font-size-base * 1.25 !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-medium:          500 !default;
$font-weight-semibold:        600 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$font-weight-base:            $font-weight-normal !default;

$line-height-base:            1.5 !default;
$line-height-sm:              1.25 !default;
$line-height-lg:              2 !default;

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;







