/* You can add global styles to this file, and also import other style files */

//import custom bootstrap theme
@import 'theme-bootstrap';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

//custom styles
.button-custom{
    background-color: $axl-brand-secondary-shade ;
    color: $axl-brand-primary;
    border: 0;
}
.button-custom:hover{
    background-color: $axl-brand-secondary;
    color: $axl-brand-primary;
}
.button-custom-inv{
    background-color: $axl-brand-secondary;
    color: $axl-brand-primary;
    border: 0;
}
.button-custom-inv:hover{
    background-color: $axl-brand-secondary-shade;
    color: $axl-brand-primary;
}
.button-custom1{
    background-color: $axl-brand-secondary-shade1 ;
    color: $axl-brand-primary;
    border: 0;
}
.button-custom1:hover{
    background-color: $axl-brand-secondary-shade;
    color: $axl-brand-primary;
}
// .card-img-top {
//     width: 100%;
//    // height: 10rem;
//    height: 20vw;
//     object-fit: cover;
//     background-color: white;
// }

.payload-list ul {
  list-style-type: disc;
  padding: 0;
  max-width: 564px;

  
}
.payload-list li::marker {
  color: #F97738;
  ; /* Set dot color to red */
}

.payload-list li {
  margin-bottom: 20px;
  font-size: 18px;
  color: #FFFFFF;
  font-weight: 500;
  
}

// forms

//login
.has_error {
    border-bottom: 2px solid #ff4b4bbf !important;
}
.has_checkbox_error {
    border: 2px solid #ff4b4bbf !important;
}
.error-red {
color: rgb(255, 103, 103);
}
.success-green {
color: #b2ff7fbf;
}
.button-right{
    float: right;
}
.form-group {
    margin-bottom: 2.1875rem;
  }
  label {
    color: $axl-brand-primary;
    font-weight: 500;
    font-size: 1rem;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  input.form-control {
    border: 0;
    border-bottom: 2px solid #929292;
    border-radius: 0;
    padding: 0;
    color: $axl-brand-primary;
    background-color: transparent;
    outline: none;
    border-radius: 1rem;
    padding-left: 1rem;
    height: 2rem;
  }
  .form-control:focus {
    border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
    border-bottom: 2px solid $axl-brand-secondary !important;
  }
  input.form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $axl-additional-colour6;
    font-weight: 400;
  }
  
  input.form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: $axl-additional-colour6;
    font-weight: 400;
  }
  
  input.form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: $axl-additional-colour6;
    font-weight: 400;
  }
  
  input.form-control:-moz-placeholder {
    /* Firefox 18- */
    color: $axl-additional-colour6;
    font-weight: 400;
  }
  
// autofill colour
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px $axl-additional-colour1 inset !important;
    background-color: transparent;
}
input:-webkit-autofill{
    -webkit-text-fill-color: $axl-brand-primary !important;
    background-color: transparent;
}
input:not([value = ""]){
    background-color: transparent;
    color: white;
}

//pointer cursor
.pointer-cursor{
  cursor: pointer;
}

//hover overlay
// .hover-overlay:after{
//   content: "";  // :before and :after both require content
//   // position: absolute;
//   width: auto; // Makes the overlay smaller to accommodate the skew
//   height: auto;
//   top: 0;
//   left: 50%; // Push the element 50% of the container's width to the right
//   transform: skew(15deg) // Puts the element on an angle
//              translateX(-50%); // Moves the element 50% of its width back to the left
//   background-image: linear-gradient(120deg,#eaee44,#33d0ff);
//   object-fit: cover;
// }
// .hover-overlay:hover:after {
//   opacity: 1;
// }
// .hover-overlay:hover{
// filter: contrast(1.1);
// -webkit-filter: contrast(1.1);
// -moz-filter: contrast(1.1);
// -o-filter: contrast(1.1);
// -ms-filter: contrast(1.1);
// }


//------------check box-----------------------
  .checkbox-wrapper *,
  .checkbox-wrapper ::after,
  .checkbox-wrapper ::before {
    box-sizing: border-box;
  }
  .checkbox-wrapper .cbx {
    position: relative;
    display: block;
    float: left;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: #606062;
    background-image: linear-gradient(#474749, #606062);
    box-shadow: inset 0 1px 1px rgba(255,255,255,0.15), inset 0 -1px 1px rgba(0,0,0,0.15);
    transition: all 0.15s ease;
  }
  .checkbox-wrapper .cbx svg {
    position: absolute;
    top: 3px;
    left: 3px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #fff;
    stroke-width: 2;
    stroke-dasharray: 17;
    stroke-dashoffset: 17;
    transform: translate3d(0, 0, 0);
  }
  .checkbox-wrapper .cbx + span {
    float: left;
    margin-left: 6px;
  }
  .checkbox-wrapper {
    user-select: none;
  }
  .checkbox-wrapper label {
    display: inline-block;
    cursor: pointer;
  }
  .checkbox-wrapper input[type="checkbox"] {
    display: none;
    visibility: hidden;
  }
  .checkbox-wrapper input[type="checkbox"]:checked + .cbx {
    background-color: #606062;
    background-image: linear-gradient($axl-brand-secondary, $axl-brand-secondary-shade);
  }
  .checkbox-wrapper input[type="checkbox"]:checked + .cbx svg {
    stroke-dashoffset: 0;
    transition: all 0.15s ease;
  }


//--------------------text area----------------
textarea{
    border-radius: 0.5rem;
    color: $axl-brand-primary;
    background-color: $axl-additional-colour1;
    // background-image: linear-gradient(#474749, #606062);
    // box-shadow: inset 0 1px 1px rgba(255,255,255,0.15), inset 0 -1px 1px rgba(0,0,0,0.15);
    transition: all 0.15s ease;
    border: 0;
    resize: none;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.8rem;
    padding-right: 0.5rem;
}
textarea::-webkit-scrollbar {
  width: 0.5rem;
}
textarea::-webkit-scrollbar-track {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 1rem;
}
textarea::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 1rem;
}
textarea:focus {
  outline: none !important;
  // border:1px solid $axl-brand-secondary;
  // box-shadow: 0 0 10px $axl-brand-secondary;
}